import React from "react"
import HomeLayout from "../layouts/HomeLayout"
import { Grid, Paper } from "@material-ui/core"
import { Link } from "gatsby"

const Home = () => {
  return (
    <HomeLayout>
      <div>
      <Grid
            spacing={0}
            container
            justify="center"
            style={{
              backgroundColor: "#dadee3",
              paddingBottom: 50,
              paddingTop: 50,
            }}
          >
            <div style={{ width: "80%" }}>
              <p
                style={{
                  textAlign: "left",
                  marginTop: 30,
                  fontSize: 28,
                  color: "#ba425e",
                }}
              >
                About
              </p>

              <p>
                HARRISON ASSET MANAGEMENT is an advisory firm of NSE, BSE,
                MCX-SX, MCX, NCDEX and a depository participant with CDSL. Since
                beginning working towards being a trusted advisor and wealth
                creator for its clients and not just a company. From asset
                allocation support, trading of securities to specialized
                investment vehicles.
                <br />
                <br /> We extensively focuses on research and technology as core
                areas to shape its growth and improving client satisfaction. It
                has built a robust infrastructure to meet growing requirements
                of its clients and to over time bound delivery of quality
                services.
                <br />
                <br /> We are entrepreneurs; all inspired by a vision of a world
                where we create a one stop financial hub & we’re a door you
                should knock when you think of Finance! With a robust presence
                across Equity & commodity broking, Mutual Fund Distribution,
                Insurance Broking, Management Consulting, Investment Banking,
                Infrastructure Consulting.
              </p>

              <p
                style={{
                  textAlign: "left",
                  marginTop: 30,
                  fontSize: 28,
                  color: "#ba425e",
                }}
              >
                Portfolio Management Services
              </p>

              <p>
                At HARRISON's, our Portfolio Management Services are highly
                customized to cater to your needs, and enable you to achieve
                your financial goals. <br /> <br /> Our prime focus is to create
                wealth for the clients & our expertise in the areas of extensive
                research, highly qualified investment team and a rich experience
                of over a decade in the financial services space, enables us to
                achieve this goal for you. <br /> <br /> We manage existing
                portfolios as well as build new distinct portfolios for you
                based on your investment objectives and risk appetite, for which
                we oﬀer a wide range of strategies including some focused
                thematic strategies, which aim at maximizing the investors
                wealth while making investments in diversiﬁed sectors which have
                a potential to grow at a faster pace, with a sustainable
                management & corporate governance standards.
              </p>
            </div>
          </Grid>
      </div>
      <div style={{ padding: 20, width: "100%" }}>
        <Grid container spacing={0} align="center" justify="center">
          <p
            style={{
              fontSize: 26,
              color: "#6AA5E3",
              fontWeight: "bold",
            }}
          >
            Thank You for <br /> Investing your Trust
          </p>
          <div style={{}} className="row">
            {[
              {
                name: "calculator",
                button: [
                  {
                    link: "/planCalculator",
                    text: "Investment Plan calculator",
                  },
                ],
                image: require("../../assets/Icons/calculator.svg"),
              },
              {
                name: "clients",
                button: [
                  { link: "/home", text: "Client Login required" },
                  {
                    link: "/client/accountStatement",
                    text: "Account Statement",
                  },
                ],
                image: require("../../assets/Icons/bank-account.svg"),
              },
              {
                name: "aboutUs",
                button: [{ link: "/aboutUs", text: "About Us" }],
                image: require("../../assets/Icons/team.svg"),
                link: "/planCalculator",
              },
              {
                name: "contactUs",
                button: [
                  {
                    link: "https://wa.me/+917708106595?text=Welcome",
                    text: "Chat with us",
                  },
                ],
                image: require("../../assets/Icons/whatsapp.svg"),
                link: "/planCalculator",
              },
            ].map(slide => {
              return (
                <Grid
                  item
                  xs={12}
                  lg={3}
                  xl={3}
                  md={2}
                  sm={2}
                  style={{
                    width: "100%",
                    padding: 20,
                  }}
                >
                  <Paper
                    style={{
                      background: "rgb(104,102,233)",
                      background:
                        "linear-gradient(0deg, rgba(104,102,233,1) 0%, rgba(106,165,227,1) 100%)",
                      height: "100%",
                      padding: 10,
                      width: 300,
                    }}
                  >
                    <img
                      src={slide.image}
                      width={150}
                      style={{
                        display: "block",
                        margin: 10,
                        marginBottom: 20,
                        marginTop: 40,
                      }}
                    />
                    {slide.button.map(buttonText => {
                      return (
                        <Link to={buttonText.link}>
                          <button
                            type="button"
                            class="btn btn-light"
                            style={{ margin: 10 }}
                          >
                            {buttonText.text}
                          </button>
                        </Link>
                      )
                    })}
                  </Paper>
                </Grid>
              )
            })}
          </div>
        </Grid>
      </div>
    </HomeLayout>
  )
}

export default Home
